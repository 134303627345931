import { Button } from 'antd'
import Clickable from '@eq/components/Clickable'
import { omit } from 'lodash'
import { useRouter } from 'next/router'
import { BsChevronRight } from 'react-icons/bs'
import { useIntl } from 'react-intl'
import PublicRequestPageFilters, { FilterTypes } from './PublicRequestPageFilters'

interface PublicRequestPageEmptyFilterCardProps {
  name: FilterTypes
}

function PublicRequestPageEmptyFilterCard(props: PublicRequestPageEmptyFilterCardProps) {
  const intl = useIntl()
  const router = useRouter()

  const handleRemoveFilter = (name) => {
    router.push(
      {
        pathname: router.route,
        query: omit(router.query, [name]),
      },
      undefined,
      { shallow: false }
    )
  }

  let label = props.name as string

  if (!PublicRequestPageFilters.filterTypes.includes(props.name)) return null

  if (props.name === 'author') {
    label = intl.formatMessage({ defaultMessage: 'Show your requests' })
  } else if (props.name === 'brand') {
    label = intl.formatMessage({ defaultMessage: 'Remove all branding filters' })
  } else if (props.name === 'exchangeMethod') {
    label = intl.formatMessage({ defaultMessage: 'Remove exchange method filters' })
  } else if (props.name === 'manufacturer') {
    label = intl.formatMessage({ defaultMessage: 'Remove filters for manufacturer types' })
  } else if (props.name === 'conditions') {
    label = intl.formatMessage({ defaultMessage: 'Remove filters for conditions' })
  } else if (props.name === 'quoted') {
    label = intl.formatMessage({ defaultMessage: 'Show quoted requests' })
  }

  return (
    <Clickable
      className="flex justify-between items-center py-4 cursor-pointer hover:text-info group px-2 -mx-2 focus:outline-none focus-visible:ring ring-black-300 rounded"
      onClick={() => handleRemoveFilter(props.name)}
    >
      <span className="truncate text-gray-400 text-base group-hover:text-info">{label}</span>
      <BsChevronRight className="" />
    </Clickable>
  )
}

interface PublicRequestPageEmptyFiltersProps {
  filters: Record<FilterTypes, any>
}

export default function PublicRequestPageEmptyFilters(props: PublicRequestPageEmptyFiltersProps) {
  const intl = useIntl()
  const router = useRouter()

  const handleClearFilters = () => {
    const newQuery = PublicRequestPageFilters.parseClearQuery(router.query)
    router.push(
      {
        pathname: router.route,
        query: newQuery,
      },
      undefined,
      { shallow: false }
    )
  }

  return (
    <div className="grid gap-8 grid-cols-3">
      <div className="col-span-2 flex place-items-center justify-center text-center border rounded-md px-2 py-12 bg-accent-1">
        <div>
          <h1>{intl.formatMessage({ defaultMessage: 'No results found for your search' })}</h1>
          <p>{intl.formatMessage({ defaultMessage: 'You can brose all requests by removing the filters' })}</p>
          <Button type="primary" onClick={handleClearFilters}>
            {intl.formatMessage({ defaultMessage: 'Browse all requests' })}
          </Button>
        </div>
      </div>
      <div className="col-span-1">
        <h3>{intl.formatMessage({ defaultMessage: 'Suggestions' })}</h3>
        <div>
          {Object.keys(props.filters || {}).map((key) => (
            <PublicRequestPageEmptyFilterCard key={key} name={key as any} />
          ))}
        </div>
      </div>
    </div>
  )
}

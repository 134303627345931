import { cn } from '@eq/utils/classnames'
import { Popover, PopoverProps, Tooltip } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useState } from 'react'

interface MapsIframePopProps extends PopoverProps {
  onContentHover?: () => void
  address: string
  tooltip?: string
}

export default function MapsIframePop({
  autoAdjustOverflow = true,
  arrowPointAtCenter = true,
  children,
  address,
  content,
  className = '',
  onContentHover,
  tooltip = 'Show Destination',
  ...props
}: MapsIframePopProps) {
  const [isOpen, setOpen] = useState(false)
  const { md } = useBreakpoint()
  if (!address) return null

  return (
    <Popover
      autoAdjustOverflow={autoAdjustOverflow}
      arrowPointAtCenter={arrowPointAtCenter}
      placement={props.placement ? props.placement : !md ? 'bottomRight' : 'topLeft'}
      trigger={props.trigger || ['click']}
      onVisibleChange={setOpen}
      content={
        <div className="space-y-2" onMouseEnter={onContentHover}>
          <iframe
            title="map-location"
            width={600}
            height="450"
            className="border-none max-w-full"
            style={{ maxWidth: 'calc(100vw - 50px)' }}
            loading="lazy"
            allowFullScreen
            src={`https://www.google.com/maps/embed/v1/search?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&q=${encodeURIComponent(
              address
            )}`}
          />
          {content}
        </div>
      }
      {...props}
    >
      <Tooltip title={isOpen ? undefined : tooltip}>
        <div className={cn('maps-iframe-pop-component', className)}>{children}</div>
      </Tooltip>
    </Popover>
  )
}

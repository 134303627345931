import { Button, Drawer, Modal } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import TeamCards from '@eq/components/TeamCards'
import { useTeams } from '@eq/hooks/useUser'
import Link from 'next/link'
import Router from 'next/router'
import { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDisclosure } from 'react-use-disclosure'

interface QuoteButtonProps extends ButtonProps {
  uid: string

  /**
   * Disable the quote button
   *
   * Used to disable the QUOTE button in a RFQList card on the request page, if the user viewing it is the author
   */
  disabled?: boolean
  isTransport?: boolean
}

const newQuotationPath = (requestId, teamId, isTransport) => {
  return `/dashboard/${teamId}/${isTransport ? 'transport-quote' : 'quote'}?rfq=${requestId}`
}

const QuoteButton = ({ isTransport, uid, ...props }: QuoteButtonProps) => {
  const { md } = useBreakpoint()
  const { isOpen, open, close } = useDisclosure()
  const intl = useIntl()
  const teams = useTeams()

  const handleQuote = useCallback(async () => {
    if (teams?.[1]) {
      // if more than one team we want the user to select a team
      if (md) {
        return Modal.info({
          title: <h2>{'Which team would you like to use?'}</h2>,
          icon: false,
          content: (
            <div className="-mx-8">
              <TeamCards
                dataSource={teams}
                onClick={(teamId) => {
                  Modal.destroyAll()
                  Router.push(newQuotationPath(uid, teamId, isTransport))
                }}
              />
            </div>
          ),
          okText: 'Cancel',
          centered: true,
        })
      } else {
        return open()
      }
    } else if (teams?.[0]) {
      // if there is only one team, we want the user to be directed to the quotation page
      return Router.push(newQuotationPath(uid, teams?.[0]?.slug, isTransport))
    } else {
      // if there is no teams, we redirect to the teams pricing page (see if we can keep the request id in query)
      return Router.push(`/teams/create?next=${newQuotationPath(uid, '[teamSlug]', isTransport)}`)
    }
  }, [teams, uid, open, md, isTransport])

  if (isTransport && process.env.NODE_ENV !== 'development') return null

  if (teams.length > 1) {
    return (
      <>
        <Button type="primary" onClick={handleQuote} {...props}>
          {props.children ? props.children : <FormattedMessage defaultMessage="Quote" tagName="span" />}
        </Button>
        {!md && (
          <Drawer
            visible={isOpen}
            placement="bottom"
            onClose={close}
            title={intl.formatMessage({ defaultMessage: 'Which team would you like to use?' })}
            height={getDrawerHeight(teams?.length ?? 0)}
          >
            <TeamCards
              dataSource={teams}
              onClick={(teamId) => {
                close()
                Router.push(newQuotationPath(uid, teamId, isTransport))
              }}
            />
          </Drawer>
        )}
      </>
    )
  }

  const quoteLink = teams?.[0]?.slug
    ? newQuotationPath(uid, teams?.[0].slug, isTransport)
    : `/teams/create?next=${newQuotationPath(uid, '[teamSlug]', isTransport)}`

  return (
    <Link href={quoteLink}>
      <a>
        <Button type="primary" {...props}>
          {props.children ? props.children : <FormattedMessage defaultMessage="Quote" tagName="span" />}
        </Button>
      </a>
    </Link>
  )
}

const getDrawerHeight = (length: number) => {
  if (length < 9) return `${length}5%`
  else '85%'
}

export default QuoteButton

const fallbackNumber = (num, replace: number): number => (isNaN(Number(num)) ? replace : Number(num))

const checkValidPage = (page) => {
  if (page == 0) {
    // For better visual representation indexing of pages start at 1 instead of 0,
    // this also allows us to align with sanity `count` it it also
    // aligns with the Pagination component of ant-design
    if (process.env.NODE_ENV === 'development') {
      throw new Error('@eq/utils/pagination should start at 1 instead of 0')
    } else {
      console.error('@eq/utils/pagination should start at 1 instead of 0')
    }
  }
}

/**
 * @param page index starting from `1`
 * @param limit The limit of items per page
 * @param defaultLimit The fallback limit used if limit is NaN
 *
 *```
 * // Example page indexing
 * getPagination(1, 3)
 *  -> starts at `0` and ends at `2`
 * getPagination(2, 3)
 *  -> starts at `1` and ends at `3`
 * ```
 */
export const getPagination = (page, limit, defaultLimit = 20) => {
  checkValidPage(page)

  if (!page || !limit) {
    return { pageStart: 0, pageEnd: defaultLimit - 1, limit: limit | defaultLimit, shouldPaginate: false }
  }

  const queryPage = fallbackNumber(page, 1)
  const queryLimit = fallbackNumber(limit, defaultLimit)

  if (queryPage == 1 || queryPage == 0) {
    return {
      pageStart: 0,
      pageEnd: queryLimit - 1,
      limit: queryLimit,
      shouldPaginate: true,
    }
  }

  const pageStart = Number(queryPage * queryLimit) - 2
  const pageEnd = Number(pageStart + queryLimit) - 1
  return { pageStart, pageEnd, limit: queryLimit, shouldPaginate: true }
}

export const paginationQuery = (page: number, limit: number) => {
  checkValidPage(page)
  return `page=${page || 1}&limit=${limit}`
}

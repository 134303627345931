import { DeleteOutlined } from '@ant-design/icons'
import { Checkbox, CheckboxProps, Empty } from 'antd'
import Button from 'antd-button-color'
import { SelectProps } from 'antd/lib/select'
import { tuple } from 'antd/lib/_util/type'
import Select from '@eq/components/Select'
import { exchangeMethodMessages, machineryMessages } from '@eq/i18n/common'
import { isEqual, omit, uniq } from 'lodash'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useThrottledCallback } from 'use-debounce'
import { exchangeMethodTypes, machineryConditionsTypes, machineryManufacturerTypes } from '@eezyquote/server.schema'

interface PublicRequestPageFiltersProps {
  filterOptions: {
    brands: string[]
  }
}

const useSearch = () => {
  const router = useRouter()

  const handleSearch = (query: Record<string, any>, remove?: string) => {
    const newQuery = { ...router.query, ...query }
    router.push(
      {
        pathname: router.route,
        query: remove ? omit(newQuery, [remove]) : newQuery,
      },
      undefined,
      { shallow: false }
    )
  }

  const handleClearSearch = () => {
    const newQuery = parseClearQuery(router.query)
    router.push(
      {
        pathname: router.route,
        query: newQuery,
      },
      undefined,
      { shallow: false }
    )
  }

  const { conditions = [], manufacturer = [], brand = [], exchangeMethod = [], quoted = undefined, author = undefined } = router.query

  return {
    handleSearch,
    handleClearSearch,
    brand: Array.isArray(brand) ? brand : [brand],
    conditions: Array.isArray(conditions) ? conditions : [conditions],
    manufacturer: Array.isArray(manufacturer) ? manufacturer : [manufacturer],
    exchangeMethod: Array.isArray(exchangeMethod) ? exchangeMethod : [exchangeMethod],
    quoted: Array.isArray(quoted) ? quoted?.[0] : quoted,
    author: Array.isArray(author) ? author?.[0] : author,
  }
}

export default function PublicRequestPageFilters(props: PublicRequestPageFiltersProps) {
  const intl = useIntl()
  const { conditions, manufacturer, brand, exchangeMethod, quoted, author, handleSearch, handleClearSearch } = useSearch()

  return (
    <div className="public-request-page-filters">
      <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <SelectFilters
          className="w-full"
          name="brand"
          value={brand}
          onSearch={handleSearch}
          placeholder={intl.formatMessage({ defaultMessage: 'Filter by brand' })}
          options={uniq(props.filterOptions?.brands || []).map((brand, i) => ({ key: `_${brand}-${i}`, value: brand, label: brand }))}
        />
        <SelectFilters
          className="w-full"
          name="conditions"
          value={conditions}
          onSearch={handleSearch}
          placeholder={intl.formatMessage({ defaultMessage: 'Filter by condition' })}
          options={machineryConditionsTypes.map((condition) => ({
            value: condition,
            label: intl.formatMessage(machineryMessages.conditions.labels(condition)),
          }))}
        />
        <SelectFilters
          className="w-full"
          name="manufacturer"
          value={manufacturer}
          onSearch={handleSearch}
          placeholder={intl.formatMessage({ defaultMessage: 'Filter by manufacturer' })}
          options={machineryManufacturerTypes.map((manufacturer) => ({
            value: manufacturer,
            label: intl.formatMessage(machineryMessages.manufacturer.labels(manufacturer)),
          }))}
        />
        <SelectFilters
          className="w-full"
          name="exchangeMethod"
          value={exchangeMethod}
          onSearch={handleSearch}
          placeholder={intl.formatMessage({ defaultMessage: 'Filter by exchange method' })}
          options={exchangeMethodTypes.map((method) => ({
            value: method,
            label: intl.formatMessage(exchangeMethodMessages(method)),
          }))}
        />
        <Button className="sm:col-span-2 lg:hidden" onClick={handleClearSearch}>
          <span>{intl.formatMessage({ defaultMessage: 'Clear Filters' })}</span>
        </Button>
      </div>
      <div className="flex justify-between items-center">
        <div className="py-2">
          <CheckboxFilter name="author" option="hidden" onSearch={handleSearch} checked={author === 'hidden'} text="Hide my requests" />
          <CheckboxFilter name="quoted" option="hidden" onSearch={handleSearch} checked={quoted === 'hidden'} text="Hide quoted requests" />
          <CheckboxFilter name="author" option="only" onSearch={handleSearch} checked={author === 'only'} text="Only show my requests" />
        </div>
        <Button className="hidden lg:block" onClick={handleClearSearch} icon={<DeleteOutlined />}>
          <span>{intl.formatMessage({ defaultMessage: 'Clear Filters' })}</span>
        </Button>
      </div>
    </div>
  )
}

type HandleSearchType = ReturnType<typeof useSearch>['handleSearch']

interface SelectFilterProps extends Required<Pick<SelectProps<any>, 'placeholder' | 'options'>> {
  name: string
  value: string[]
  onSearch: HandleSearchType
  className?: string
}

const SelectFilters = ({ value, name, onSearch, ...props }: SelectFilterProps) => {
  const [selectedValue, setValue] = useState(value)

  const handleSearch = useCallback((val) => onSearch({ [name]: val }), [onSearch, name])

  const throttled = useThrottledCallback((selection) => handleSearch(selection), 3000, { trailing: true, leading: false })

  useEffect(() => {
    return () => {
      throttled.cancel()
    }
  }, [throttled])

  return (
    <Select
      defaultValue={selectedValue}
      maxTagCount="responsive"
      mode="multiple"
      allowClear
      onClear={() => onSearch({ [name]: [] })}
      className={props.className}
      onChange={(val) => {
        setValue(val as any)
        // lets throttle the selection to allow the user to finish their selection.
        throttled(val)
      }}
      onDropdownVisibleChange={(open) => {
        if (!open && !isEqual(selectedValue, value)) {
          // lets handle the search if the dialog closes because we expect them to be finished.
          handleSearch(selectedValue)
        }
      }}
      notFoundContent={<Empty className="my-2" image={Empty.PRESENTED_IMAGE_SIMPLE} description={`No ${name}'s found`} />}
      {...props}
    />
  )
}

const CheckboxFilter = ({
  name,
  option,
  checked,
  text,
  onSearch,
  ...props
}: CheckboxProps & { name: string; option: string; onSearch: HandleSearchType; text?: string }) => {
  const [value, setValue] = useState(checked)

  const hiddenIfChecked = (value: any) => {
    if (value) return value
    return undefined
  }

  const handleSearch = useCallback(
    (flag) => onSearch({ [name]: hiddenIfChecked(flag ? option : false) }, !flag ? name : undefined),
    [onSearch, name, option]
  )

  const throttled = useThrottledCallback((selection) => handleSearch(selection), 1000, { trailing: true, leading: true })

  useEffect(() => {
    return () => {
      throttled.cancel()
    }
  }, [throttled])

  return (
    <Checkbox
      {...props}
      checked={value}
      onChange={(e) => {
        setValue(e.target?.checked)
        throttled(e.target?.checked)
      }}
    >
      {text}
    </Checkbox>
  )
}

const filterTypes = tuple('conditions', 'manufacturer', 'brand', 'exchangeMethod', 'author', 'quoted')
export type FilterTypes = typeof filterTypes[number]

const parseClearQuery = (query) => {
  const newQuery = { ...query }
  delete newQuery.brand
  delete newQuery.conditions
  delete newQuery.exchangeMethod
  delete newQuery.author
  delete newQuery.quoted
  return newQuery
}

PublicRequestPageFilters.parseClearQuery = parseClearQuery
PublicRequestPageFilters.filterTypes = filterTypes

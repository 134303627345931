import { Empty } from 'antd'
import PublicRequestProductList from '@eq/components/Products/PublicRequestProductList'
import React from 'react'
import { cn } from '@eq/utils/classnames'
import { isTransportProduct } from '@eezyquote/server.schema'
import { PUBLIC_REQUEST_RESULT } from './PublicRequestsPage'
import PublicRequestsListItem from './PublicRequestsPageListItem'

interface PublicRequestPageListProps extends Pick<PUBLIC_REQUEST_RESULT, 'requests'> {
  className?: string
  quoteDisabled?: boolean
}

export default function PublicRequestsPageList(props: PublicRequestPageListProps) {
  return (
    <div className={cn('public-requests-page-list', props.className)}>
      {props.requests?.[0] ? (
        props.requests.map((request) => (
          <PublicRequestsListItem
            key={request._id}
            quoteDisabled={props.quoteDisabled || isTransportProduct(request?.products?.[0]?.variant)}
            {...request}
          >
            <PublicRequestProductList products={request.products} transport={request.transport} />
          </PublicRequestsListItem>
        ))
      ) : (
        <div className="flex place-items-center justify-center">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Requests Found" />
        </div>
      )}
    </div>
  )
}

import { isTransportProduct } from '@eezyquote/server.schema'
import MapsIframePop from '@eq/components/MapsIframePop'
import Note from '@eq/components/Note'
import QuoteButton from '@eq/components/QuoteButton'
import { PUBLIC_REQUEST } from '@eq/pages/api/requests/[uid]'
import { cn } from '@eq/utils/classnames'
import dayjs from '@eq/utils/dayjs'
import { Alert, Button, Space, Tag, Tooltip } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Text from 'antd/lib/typography/Text'
import Link from 'next/link'
import { ReactNode } from 'react'
import { BsInfoCircle } from 'react-icons/bs'
import { FaGlobeAfrica } from 'react-icons/fa'
import { FormattedMessage, useIntl } from 'react-intl'
import Avatar from '../Avatar'
import Fieldset from '../Fieldset'

interface PublicRequestSListItemProps extends PUBLIC_REQUEST {
  quoteDisabled?: boolean
  children?: ReactNode
}

const ExchangeMethod = (props: Pick<PublicRequestSListItemProps, 'exchangeMethod'>) => {
  const intl = useIntl()
  const isBoth = props.exchangeMethod.length > 1
  const isDelivery = !isBoth && props.exchangeMethod.includes('delivery')
  const isCollect = !isDelivery && props.exchangeMethod.includes('collect')

  const item = (value: string) => (
    <div className="flex space-x-1 items-center">
      <BsInfoCircle />
      <span>{value}</span>
    </div>
  )

  if (isBoth) return item(intl.formatMessage({ defaultMessage: 'Quote on collect & delivery' }))
  if (isDelivery) return item(intl.formatMessage({ defaultMessage: 'Quote on delivery' }))
  if (isCollect) return item(intl.formatMessage({ defaultMessage: 'Quote on collect' }))
  return null
}

const MapLocation = (props: Pick<PublicRequestSListItemProps, 'location'> & { onContentHover?: () => void }) => {
  if (!props.location) return null
  return (
    <MapsIframePop
      onContentHover={props.onContentHover}
      address={props.location}
      content={<Alert type="info" message="This is an estimated location" />}
    >
      <FaGlobeAfrica className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-900" />
    </MapsIframePop>
  )
}

export default function PublicRequestsListItem(props: PublicRequestSListItemProps) {
  const bp = useBreakpoint()
  const intl = useIntl()

  const quoteDisabled = props.isAuthor || props.quoteDisabled

  const exchangeMethodTexts = <ExchangeMethod exchangeMethod={props.exchangeMethod} />
  const mapLocationField = <MapLocation location={props.location} />
  return (
    <div className="relative">
      <Fieldset
        className={cn('', {
          'shadow-lg': !props.isAuthor,
        })}
      >
        <Fieldset.Content>
          <div className="space-x-2 mb-4 flex items-center">
            {props.isAuthor && (
              <Tooltip
                title={
                  props.isAuthor
                    ? props?.authorType === 'teams'
                      ? intl.formatMessage(
                          {
                            defaultMessage: 'This request was created on the <b>{name}</b> team.',
                          },
                          { name: props.authorName || '' }
                        )
                      : intl.formatMessage({
                          defaultMessage: 'You are the author of this request.',
                        })
                    : undefined
                }
                placement="topLeft"
                color="gold"
              >
                <div>
                  <Avatar
                    src={props.authorImage}
                    alt={props.authorName || ''}
                    slug={props.authorEmail || props.authorSlug || ''}
                    size={20}
                  />
                </div>
              </Tooltip>
            )}
            <div className="flex-grow">
              <Link href={`/request/${props.uid}`}>
                <a className="items-center">Request #{props.uid}</a>
              </Link>
            </div>
            <div className="space-x-1">
              {!!Number(props.totalQuotes) && (
                <Tag>
                  <FormattedMessage
                    defaultMessage="<small>QUOTES :</small> {count}"
                    values={{
                      count: <span className="font-medium">{props.totalQuotes}</span>,
                      small: (...chunks) => <small className="font-medium">{chunks}</small>,
                    }}
                  />
                </Tag>
              )}
              <Text>{dayjs(props._createdAt).format('LL')}</Text>
            </div>
          </div>
          {!bp.md ? (
            <div className="space-y-2">
              <div className="flex justify-between px-2">
                {exchangeMethodTexts} {mapLocationField}
              </div>
              <div>{props.children}</div>
            </div>
          ) : (
            props.children
          )}
          {props.notes && (
            <div
              className={cn('p-4 border mt-2 rounded-md', {
                'flex items-baseline space-x-2': props.notes.length < 200,
              })}
            >
              <b>Note:</b>
              <Note note={props.notes} />
            </div>
          )}
        </Fieldset.Content>
        <Fieldset.Footer className={!bp.md ? 'p-0' : undefined}>
          {bp.md ? (
            <>
              <Fieldset.Footer.Status>
                <div className="flex space-x-2 items-center">
                  {mapLocationField} {exchangeMethodTexts}
                </div>
              </Fieldset.Footer.Status>
              <Fieldset.Footer.Actions>
                <Space>
                  <Link href={`/request/${props.uid}`}>
                    <a>
                      <Button type="default">
                        <FormattedMessage defaultMessage="View" />
                      </Button>
                    </a>
                  </Link>
                  {!quoteDisabled && <QuoteButton uid={props.uid} />}
                </Space>
              </Fieldset.Footer.Actions>
            </>
          ) : (
            <div className="w-full h-14 divide-x flex divide-accent-2">
              <Link href={`/request/${props.uid}`}>
                <a className="flex-grow h-full">
                  <Button className="h-full w-full border-none rounded-none" type="text">
                    <FormattedMessage defaultMessage="View" />
                  </Button>
                </a>
              </Link>
              {!quoteDisabled && (
                <QuoteButton
                  uid={props.uid}
                  className="w-1/2 h-full border-l border-r-0 border-t-0 border-b-0 rounded-none"
                  type="text"
                  isTransport={isTransportProduct(props?.products?.[0]?.variant)}
                />
              )}
            </div>
          )}
        </Fieldset.Footer>
      </Fieldset>
    </div>
  )
}

import Button from 'antd-button-color'
import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'
import Layout from '@eq/components/Layout'
import Link from 'next/link'
import { getAllPublicRequests } from '@eq/pages/api/requests/[uid]'
import React, { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { PromiseValue } from 'type-fest'
import PublicRequestPageFilters from './PublicRequestPageFilters'
import PublicRequestsPageList from './PublicRequestsPageList'

export const LIMIT_PUBLIC_REQUEST_RESULT = 20
export type PUBLIC_REQUEST_RESULT = PromiseValue<ReturnType<typeof getAllPublicRequests>>

interface PublicRequestsPageProps extends Omit<PUBLIC_REQUEST_RESULT, 'total'> {
  currentPage: number
  pageSize: number
  children?: ReactNode
  filterOptions: {
    brands: string[]
  }
}

export default function PublicRequestsPage(props: PublicRequestsPageProps) {
  return (
    <Layout className="public-requests-page-component">
      <header className="bg-accent-1 border-b-1 border-accent-2 pt-6 min-h-44">
        <div className="grid grid-cols-2 container">
          <div>
            <Title level={2} className="mb-0 leading-snug">
              <FormattedMessage
                defaultMessage="Requests <small>({count})</small>"
                values={{ count: props.requests.length, small: (...chunks) => <span className="text-base align-text-top">{chunks}</span> }}
              />
            </Title>
            <Text className="opacity-50 mt-0">
              <FormattedMessage defaultMessage="Browse the list of requests for quotations" />
            </Text>
          </div>
          <div className="flex justify-end pt-2 space-x-1">
            <Link href="/request/create">
              <a>
                <Button type="info">
                  <FormattedMessage defaultMessage="New Request" />
                </Button>
              </a>
            </Link>
          </div>
          <div className="pt-4 col-span-2">
            <PublicRequestPageFilters filterOptions={props.filterOptions} />
          </div>
        </div>
      </header>

      <main className="py-12 container min-h-screen">
        {props.requests?.[0] && <PublicRequestsPageList requests={props.requests} className="space-y-8" />}
        {props.children}
      </main>
    </Layout>
  )
}

PublicRequestsPage.Loading = () => <div>SHOULD SHOW FALLBACK LOADING !!!! SKELETON</div>

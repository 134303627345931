import { Pagination, PaginationProps } from 'antd'
import { useRouter } from 'next/router'
import React from 'react'
import { cn } from '@eq/utils/classnames'
import { paginationQuery } from '@eq/utils/pagination'

interface PublicRequestsPagePaginationProps extends PaginationProps {
  className?: string
}

export default function PublicRequestsPagePagination({ className = '', ...props }: PublicRequestsPagePaginationProps) {
  const router = useRouter()
  function changePagination(current, pageSize) {
    router.push(`/requests?${paginationQuery(current, pageSize)}`)
  }
  return (
    <Pagination
      className={cn('public-request-page-pagination-component', className)}
      onChange={changePagination}
      onShowSizeChange={changePagination}
      {...props}
    />
  )
}

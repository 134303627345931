import { List } from 'antd'
import Avatar from '@eq/components/Avatar'
import { UserTeam } from '@eq/interfaces/users'
import { cn } from '@eq/utils/classnames'

interface TeamCardsProps {
  dataSource?: UserTeam[]
  onClick?: (id: string) => void
}

export default function TeamCards(props: TeamCardsProps) {
  return (
    <List
      dataSource={props?.dataSource ?? []}
      renderItem={(team) => (
        <List.Item
          key={team.slug}
          onClick={() => props?.onClick?.(team.slug)}
          className={cn('px-2', {
            'cursor-pointer hover:bg-accent-1': props?.onClick,
          })}
        >
          <List.Item.Meta
            avatar={<Avatar src={team.image} alt={team.slug || team._id} />}
            title={team.name || team.slug}
            description={team?.slug ? `@${team.slug}` : undefined}
          />
        </List.Item>
      )}
    />
  )
}

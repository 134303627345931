/**
 * IMPORTANT!
 *
 * THIS IS JUST AN ALIAS FILE FOR THE SELECT COMPONENT,
 * IT IS TO AVOID THE `useLayoutEffect` ISSUE WITH SSR
 * https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
 *
 */

import { Select as AntSelect, SelectProps } from 'antd'
import { forwardRef } from 'react'
import { SelectValue, RefSelectProps } from 'antd/lib/select'

function InternalSelect<VT extends SelectValue = SelectValue>(props, ref) {
  return <AntSelect<VT> ref={ref} {...props} />
}

// https://github.com/ant-design/ant-design/blob/b68512f479217f5724555b63ee81825257edcc45/components/select/index.tsx#L148

const SelectRef = forwardRef(InternalSelect) as <VT extends SelectValue = SelectValue>(
  props: SelectProps<VT> & { ref?: React.Ref<RefSelectProps> }
) => React.ReactElement

declare type InternalSelectType = typeof SelectRef

interface SelectInterface extends InternalSelectType {
  SECRET_COMBOBOX_MODE_DO_NOT_USE: string
  Option: typeof AntSelect.Option
  OptGroup: typeof AntSelect.OptGroup
}

const Select = SelectRef as SelectInterface

Select.SECRET_COMBOBOX_MODE_DO_NOT_USE = AntSelect.SECRET_COMBOBOX_MODE_DO_NOT_USE
Select.Option = AntSelect.Option
Select.OptGroup = AntSelect.OptGroup

export const SelectOption = Select.Option

export default Select
